import React, { useEffect, useState } from 'react'
import LabelComponent from './LabelComponent/_index'
import ResultSearch from './ResultSearch/_index'

import CorrespondingJSON from '../../../assets/data/Corresponding.json'

import { CityProps, ICitysFromStates } from './types'

import { Container } from './style'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

const citysFromStates: ICitysFromStates = CorrespondingJSON

const SearchCorresponding = () => {
  const [ uf, setUf ] = useState<string | undefined>()
  const [ city, setCity ] = useState<string | undefined>()
  const [ neighborhood, setNeighborhood ] = useState<string | undefined>()
  const [ corresponding, setCorresponding ] = useState<CityProps[]>()
  const [ result, setResult ] = useState<boolean>(false)
  const [ uniqueCities, setUniqueCities ] = useState<string[]>([])
  const [ color, setColor ] = useState<string | undefined>('#B6B7BB')

  useEffect(() => {
    setCity(undefined)
    setNeighborhood(undefined)
  }, [ uf ])

  useEffect(() => {
    if (uf) {
      const cities = citysFromStates[0][uf]
        .map((cityObj: CityProps) => cityObj.city)
        .filter((city: string, index: number, self: string[]) => self.indexOf(city) === index)
      setUniqueCities(cities)
    }
  }, [ uf ])

  useEffect(() => {
    setNeighborhood(undefined)
  }, [ city ])

  useEffect(() => {
    if (uf && city && neighborhood) {
      const filteredCorresponding: CityProps[] = citysFromStates[0][uf]
        .filter((cityObj: CityProps) => cityObj.city === city && cityObj.neighborhood === neighborhood)
      setCorresponding(filteredCorresponding)
    }
  }, [ uf, city, neighborhood ])

  const handleUfChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setUf(event.target.value)
    event.target.value !== 'Selecione o estado' && setColor('#161616')
    setResult(false)
  }

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCity(event.target.value)
    event.target.value !== 'Selecione a cidade' && setColor('#161616')
    event.target.value !== '' && setResult(false)
  }

  const handleNeighborhoodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setNeighborhood(event.target.value)
    event.target.value !== 'Selecione o bairro' && setColor('#161616')
    event.target.value !== '' && setResult(false)
  }

  return (
    <section>
      <Container>
        <div className='row mt-md-5 align-items-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            <LabelComponent text='Estado' htmlFor='estado' />
            <span className='select mb-3'>
              <select
                value={uf}
                onChange={handleUfChange}
                style={{ color: uf && color }}
              >
                <option value='' className='text-grayscale--300'>Selecione o estado</option>
                {Object.keys(citysFromStates[0]).map((uf: string) => (
                  <option key={uf} value={uf}>
                    {uf}
                  </option>
                ))}
              </select>
            </span>

            <LabelComponent text='Cidade' htmlFor='cidade' />
            <span className='select mb-3'>
              <select
                value={city}
                onChange={handleCityChange}
                style={{ color: city && color }}
              >
                <option className='text-grayscale--300'>Selecione a cidade</option>
                {uf &&
                  uniqueCities.map((city: string) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                ))}
              </select>
            </span>

            <LabelComponent text='Bairro' htmlFor='bairro' />
            <span className='select'>
              <select
                value={neighborhood}
                onChange={handleNeighborhoodChange}
                style={{ color: neighborhood && color }}
              >
                <option className='text-grayscale--300'>Selecione o bairro</option>
                {city &&
                  uf &&
                  citysFromStates[0][uf]
                    .filter((cityObj: CityProps) => cityObj.city === city)
                    .reduce((uniqueNeighborhoods: CityProps[], cityObj: CityProps) => {
                      if (!uniqueNeighborhoods.some((item: CityProps) => item.neighborhood === cityObj.neighborhood)) {
                        uniqueNeighborhoods.push(cityObj)
                      }
                      return uniqueNeighborhoods
                    }, [])
                    .map((cityObj: CityProps) => (
                      <option key={cityObj.neighborhood} value={cityObj.neighborhood}>
                        {cityObj.neighborhood}
                      </option>
                ))}
              </select>
            </span>

            <div className='mt-5'>
              <button
                onClick={() => setResult(true)}
                type='submit' className='btn text-white bg-orange--linear w-100'
                title='Enviar' disabled={!uf || !city || !neighborhood || neighborhood === 'Selecione o bairro'}
              >
                Buscar correspondentes
              </button>
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-6 offset-lg-1 mt-5 mt-md-0'>
            {corresponding ? (
              <DefaultCarousel
                sm={{ items: 1 }}
                md={{ items: 1 }}
                lg={{ items: 1 }}
                xl={{ items: 1 }}
              >
                {corresponding?.map((item: CityProps) => (
                  <ResultSearch
                    key={item?.cnpj}
                    result={result}
                    corresponding={item}
                  />
              ))}
              </DefaultCarousel>
            ) : (
              <ResultSearch result={false} />
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default SearchCorresponding
